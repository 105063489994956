import Alpine from 'alpinejs'
import './chui.scss';
import {Pagelets} from '@packaged-ui/pagelets';

Alpine.start();
Pagelets.init(
    {
        defaultTarget: 'page-content',
        selector: 'a[data-uri],span[data-uri],li[data-uri],button[data-uri],[href]:not([href^="#"]):not([href=""]):not([target])',
        composedEvents: true
    }
);
